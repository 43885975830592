import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SolutionsComponent } from './solutions/solutions.component';
import { Gold965jeweleryComponent } from './gold965jewelery/gold965jewelery.component';
import { FIREBASE_CONFIG} from './app.firebase.config';
import { AngularFireModule} from 'angularfire2';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireStorageModule} from 'angularfire2/storage';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RtStockComponent } from './rt-stock/rt-stock.component';
import { LoginComponent } from './login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { AdminComponent } from './admin/admin.component';
import { RegistrationComponent } from './registration/registration.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { RegistrationThComponent } from './registration-th/registration-th.component';
import { RegistrationLangComponent } from './registration-lang/registration-lang.component';
import { JeweleryCategoryComponent } from './jewelery-category/jewelery-category.component';

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    SolutionsComponent,
    Gold965jeweleryComponent,
    RtStockComponent,
    LoginComponent,
    AdminComponent,
    RegistrationComponent,
    RegistrationThComponent,
    RegistrationLangComponent,
    JeweleryCategoryComponent,


  ],
  imports: [
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    GoogleMapsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    Ng2TelInputModule ,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
