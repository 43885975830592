<header style="position: relative; background-color: white">
  <div class = "row ">
    <div class="col-sm-12">
      <div class="col-sm-5">
      <a class="navbar-brand" href="#">
        <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjhlogo.jpg?alt=media&token=6a17dc68-5184-4777-bea9-d2fca5d7217d" alt="">
      </a>
      </div>

    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light py-0 shadow-lg" style="background-color: white; margin-left:-20px; margin-right:-20px" id="header-nav">

    <button class="navbar-toggler" (click)="isMainMenuCollapsed = !isMainMenuCollapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div [ngbCollapse]="isMainMenuCollapsed" class="collapse navbar-collapse mt-auto shadow" id="navbarSupportedContent" style="background-color: #800020">
      <ul class="navbar-nav  ul-header ml-auto">
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/home" style=" vertical-align: text-bottom; display: block;height: 150px; color: #FFDF00;" >Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/about_us" style=" vertical-align: text-bottom; display: block;height: 150px; color: white;">About Us<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/jewelery-category" style=" vertical-align: text-bottom; display: block;height: 150px; color: white;">96.5% Gold Jewelry<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/solutions" style=" vertical-align: text-bottom; display: block;height: 150px; color: white;">Our Solutions<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/contact_us" style=" vertical-align: text-bottom; display: block;height: 150px; color: white;">Contact Us<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/registration-lang" style=" vertical-align: text-bottom; display: block;height: 150px; color: white;">Register<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="https://www.jibhuionline.com" style=" vertical-align: text-bottom; display: block;height: 150px; color: white; background-color: #B5913A">Gold Online<span class="sr-only">(current)</span></a>
        </li>
      </ul>
    </div>

  </nav>


</header>






   <router-outlet></router-outlet>
