import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';

import { firestore, storage } from 'firebase';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(private storage: AngularFireStorage, private db: AngularFirestore) { }
  fileToUpload: File = null;
  ngOnInit(): void {
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
    onFileSelected(event) {
      for (const file of event.target.files) {
        const path = 'rt_stock_img/' + file.name;
        const task: storage.UploadTask = this.storage.upload(path, file).task;

        task.then(

          (uploadTaskSnapshot: firebase.storage.UploadTaskSnapshot) => {
            uploadTaskSnapshot.ref.getDownloadURL().then(
             (value) => {
                this.db.collection('rt_stock').doc('general_stock').update({
                    [file.name]:
                    {

                      updatedTime: firestore.FieldValue.serverTimestamp() ,
                      downloadURL: value
                    }
                    // firebase.firestore.FieldValue.arrayUnion("new_Item123")

                });
              }
            );
          }
        );

      }
    }

}
