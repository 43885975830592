import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutUsComponent} from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { Gold965jeweleryComponent} from './gold965jewelery/gold965jewelery.component';
import { RtStockComponent } from './rt-stock/rt-stock.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationThComponent } from './registration-th/registration-th.component';
import { RegistrationLangComponent } from './registration-lang/registration-lang.component';
import { JeweleryCategoryComponent } from './jewelery-category/jewelery-category.component';
const routes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  {path: 'home', component: HomeComponent},
  {path: 'about_us', component: AboutUsComponent},
  {path: 'contact_us', component: ContactUsComponent},
  {path: 'solutions', component: SolutionsComponent},
  {path: 'gold965jewelery', component: Gold965jeweleryComponent},
  {path: 'rt_stock', component: RtStockComponent},
  {path: 'admin', component: AdminComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registration', component: RegistrationComponent},
  {path: 'registration-th', component: RegistrationThComponent},
  {path: 'registration-lang', component: RegistrationLangComponent},
  {path: 'jewelery-category', component: JeweleryCategoryComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, AboutUsComponent,
  ContactUsComponent, SolutionsComponent, Gold965jeweleryComponent, RegistrationComponent,RegistrationLangComponent,JeweleryCategoryComponent];
