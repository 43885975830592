import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductGalleryService } from 'src/app/services/product-gallery.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { DocumentData, Query, QuerySnapshot, AngularFirestore } from 'angularfire2/firestore';
import { Router, ActivatedRoute , ParamMap } from '@angular/router';
@Component({
  selector: 'app-gold965jewelery',
  templateUrl: './gold965jewelery.component.html',
  styleUrls: ['./gold965jewelery.component.scss']
})


export class Gold965jeweleryComponent implements OnInit {

  constructor(private service: ProductGalleryService, private router: Router, private route: ActivatedRoute) {

   }
  public isMenuCollapsed = false;
  public isloading = false;
  designList = [];
  throttle = 300;
  scrollDistance = 0.2;
  rowIndexArray: any[];
  currentQuery: Query;
   showImageModal: boolean;
   enlargedImageURL: string;
   category: string;
  pagelimit = 9;
  prevQuerySnapShot: QuerySnapshot<DocumentData>;
     disabledPrevBtn = false;
     disabledNextBtn = false;

  ngOnInit(): void {
    console.log(this.route.snapshot.queryParamMap.get('category'));

    if (this.route.snapshot.queryParamMap.get('category') == null){
      this.service.getDesignsDetailQueryByDesignCatID('0001');
      this.category = 'สร้อยคอ / Necklaces';

    }else{
      console.log()
      this.service.getDesignsDetailQueryByDesignCatID(this.route.snapshot.queryParamMap.get('category'));
      if (this.route.snapshot.queryParamMap.get('designcat') === '0001'){
        this.category = 'สร้อยคอ / Necklaces';
      }else if(this.route.snapshot.queryParamMap.get('designcat') === '0002'){
        this.category = 'สร้อยมือ / Wristbands';
      }else if(this.route.snapshot.queryParamMap.get('designcat') === '0003'){
        this.category = 'กำไล / Bracelets';
      }else if(this.route.snapshot.queryParamMap.get('designcat') === '0004'){
        this.category = 'แหวน / Rings';
      }else if(this.route.snapshot.queryParamMap.get('designcat') === '0005'){
        this.category = 'จี้ / Pendants';
      }else if(this.route.snapshot.queryParamMap.get('designcat') === '0006'){
        this.category = 'ต่างหู / Earrings)';
      }
    }

    this.isMenuCollapsed = false;
    this.designList = [];

    this.currentQuery = this.service.query.limit(this.pagelimit);

    this.isloading = true;
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );

  }
  showImage(url){
    this.showImageModal = true;
    this.enlargedImageURL = url;
  }
  hideImage(){
    this.showImageModal = false;
  }

   nextPage(){
        this.currentQuery = this.service.query
         .startAfter(this.prevQuerySnapShot.docs[this.prevQuerySnapShot.docs.length - 1])
         .limit(this.pagelimit);
        this.isloading = true;
        this.currentQuery.get().then(
           (snapshot: QuerySnapshot<DocumentData>) => {
             snapshot.forEach(
               doc => {
                 const data = doc.data();
                 const id = doc.id;
                 this.designList.push(data);

                 return {id, ...data};
               }
             );
             this.isloading = false;
             this.prevQuerySnapShot = snapshot;
           }
         );


  }

  previousPage(){

   this.currentQuery.get().then(
     (      documentSnapshots: { docs: string | any[]; }) => {
       const lastVisible = documentSnapshots.docs[0];
       this.currentQuery = this.service.query
        .endBefore(lastVisible)
        .limit(this.pagelimit);
       this.currentQuery.get().then(
          docs => {
            docs.forEach(
              doc => {
                const data = doc.data();
                const id = doc.id;
                this.designList.push(data);

                // this.designLists.push(data);
                return {id, ...data};
              }
            );
          }
        );
     }
   );
 }
  onScrollEnd(){
    this.nextPage();

  }
  queryNecklaces(){
    this.category = 'สร้อยคอ / Necklaces';
    this.designList = [];
    this.service.getNecklaceDesignsDetailQuery();
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.isloading = true;
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  queryWristbands(){
    this.category = 'สร้อยมือ / Wristbands';
    this.designList = [];
    this.service.getWristBandsDesignsDetailQuery();
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.isloading = true;
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  queryBracelets(){
    this.category = 'กำไล / Bracelets';
    this.designList = [];
    this.service.getBraceletsDesignsDetailQuery();
    this.isloading = true;
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  queryRings(){
    this.category = 'แหวน / Rings';
    this.designList = [];
    this.service.getRingsDesignsDetailQuery();
    this.isloading = true;
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  queryPendants(){
    this.category = 'จี้ / Pendants';
    this.designList = [];
    this.service.getPendantsDesignsDetailQuery();
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  queryEarrings(){
    this.category = 'ต่างหู / Earrings)';
    this.designList = [];
    this.service.getEarringsDesignsDetailQuery();
    this.isloading = true;
    this.currentQuery = this.service.query.limit(this.pagelimit);
    this.currentQuery.get().then(
      (snapshots: QuerySnapshot<DocumentData>) => {
        snapshots.forEach(
          doc => {
            const data = doc.data();
            const id = doc.id;
            this.designList.push(data);
            return {id, ...data};
          }
        );
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    );
  }
  getExpenses(event?: PageEvent) {
    if (event?.pageIndex > event?.previousPageIndex){
      // nextPage
      this.nextPage();
    }
    if (event?.pageIndex < event?.previousPageIndex){
      this.previousPage();
    }
}
}
