import { Component, OnInit } from '@angular/core';
import { QuerySnapshot, DocumentData, Query, DocumentSnapshot} from 'angularfire2/firestore';
import { RtStockService } from 'src/app/services/rt-stock.service';

import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-rt-stock',
  templateUrl: './rt-stock.component.html',
  styleUrls: ['./rt-stock.component.scss']
})
export class RtStockComponent implements OnInit {

  constructor(private service: RtStockService, private http: HttpClient,
              private afStorage: AngularFireStorage, public afAuth: AngularFireAuth, private router: Router, private route: ActivatedRoute) { }
  public isMenuCollapsed = false;
  public isloading = false;
  rtStockList = [];
  throttle = 300;
  limit = 1;
  scrollDistance = 0.2;
  rowIndexArray: any[];
  currentQuery: Query;
   showImageModal: boolean;
   enlargedImageURL: string;
   category: string;
  pagelimit = 9;
  prevQuerySnapShot: QuerySnapshot<DocumentData>;
     disabledPrevBtn = false;
     disabledNextBtn = false;
     errorMessage: String = null;
     listOfData: any[] = [];
  ngOnInit(): void {
    if (this.afAuth.auth.currentUser == null){
      this.router.navigate(['/login'], {queryParams: {redirectURL: this.route.snapshot.url, message: 'You have not logged in or your account does not have the permission to view this page.'}});
    }

    this.isMenuCollapsed = false;
    this.rtStockList = [];
    this.service.getRTStockDocumentData().then(
      (snapshot: DocumentSnapshot<DocumentData>) => {
        const data  = snapshot.data();
        for (const key in data) {
          const value = data[key];
          this.listOfData.push(value);
        }
        const keys = Object.keys(data);
        console.log(keys);
        keys.sort((a, b) =>  data[b].jpg.updatedTime - data[a].jpg.updatedTime);

        const sorted = keys.map(key => data[key]);
        console.log('a');
        console.log(sorted);
        console.log('b');
      }
    );
  /**   this.currentQuery = this.service.query.limit(this.pagelimit);
    this.isloading = true;
    this.currentQuery.get().then(
      (snapshots:QuerySnapshot<DocumentData>)=>{
        snapshots.forEach(
          doc=>{
            const data =doc.data()
            console.log(data.img_url.path);
            const id =doc.id
            this.rtStockList.push(data);
            return {id, ...data}
          }
        )
        this.isloading = false;
        this.prevQuerySnapShot = snapshots;
      }
    ).catch(
      (failureCallback)=>{
        console.log(failureCallback);
        console.log("in catch stat");
        this.errorMessage = "You need to be a Premium Account to view updated stock page. Please contact us for more detail.";
        //this.router.navigate(['/login'],{queryParams:{'redirectURL':this.route.snapshot.url, 'message': "You need to be a Premium Account to view updated stock page. Please contact out contact number for detail."}})

      }
    )**/
  }

  nextPage(){
      /** this.currentQuery = this.service.query
        .startAfter(this.prevQuerySnapShot.docs[this.prevQuerySnapShot.docs.length-1])
        .limit(this.pagelimit);
        this.isloading = true;
        this.currentQuery.get().then(
          (snapshot:QuerySnapshot<DocumentData>)=>{
            snapshot.forEach(
              doc=>{
                const data =doc.data()
                const id =doc.id
                this.rtStockList.push(data);

                return {id, ...data}
              }
            )
            this.isloading = false;
            this.prevQuerySnapShot = snapshot;
          }
        )
        **/

 }

  onScrollEnd(){
    this.limit = this.limit + 1;

  }
}
