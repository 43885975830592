import { Component, OnInit , Inject, Renderer2} from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  center: google.maps.LatLngLiteral;
  zoom = 12;

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private documentHtml: Document,) {
 }
  markers = [{
    position: {
      lat: 13.744948,
      lng: 100.501001,
    },
    label: {
      color: 'red',
      text: 'JIB HUI',
    },
    title: 'Marker title ' ,
    options: { animation: google.maps.Animation.BOUNCE },
  }];

  addMarker() {
    this.markers.push({
      position: {
        lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
        lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: { animation: google.maps.Animation.BOUNCE },
    });
  }
  ngOnInit(): void {

      this.center = {
        lat: 13.744948,
        lng: 100.501001,
      };
      this.markers = [{
        position: {
          lat: 13.744948,
          lng: 100.501001,
        },
        label: {
          color: 'red',
          text: 'JIB HUI',
        },
        title: 'Marker title ' ,
        options: { animation: google.maps.Animation.BOUNCE },
      }];
  }



}
