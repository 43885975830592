<body>
  <div class="title div-page-title">
    <h1>Contact Us</h1>
  </div>
  <div class="container-fluid">
    <div class="mt-5">
        <p class="text-align-center fw-normal">203 Pahurat Road , Wang Buraphaphirom , Pra Nakorn , Bangkok 10200</p>

        <p class="text-align-center fw-normal thai">บริษัท ห้างทอง จิบฮุย จำกัด 203 ถนนพาหุรัด แขวงวังบูรพาภิรมย์ เขตพระนคร กรุงเทพมหานคร 10200</p>

        <p class="text-align-center fw-normal"> Tel: +662 2256511, +662 2256512, +662 2256513 Fax: +662 6237540</p>
    </div>
    <div class="gmapcontainer">
      <div class="gmap">
        <google-map width="100%" height="500px" [center]="center">
          <map-marker
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
          >
          </map-marker>
        </google-map>
      </div>
    </div>
    </div>
</body>>
<footer>

    <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
      <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
    </div>
</footer>
