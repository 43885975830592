
  <div class="title div-page-title mb-0" style="background-color:white">
    <h1 class="mb-0 defaultheader">About Us</h1>
  </div>
  <div class="imagesection mt-0">
    <img class="img-fluid" alt="Responsive image"  src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Ffronofhouse3_retouched.png?alt=media&token=977a1360-8f18-4f18-b34d-146196e6d33e"/>
  </div>

    <div class="container mt-5" style="background-color:white">
      <p class="fw-normal">Jib Hui Goldsmith Co., Ltd is one of Thailand most renowned gold jewelry and bullion wholesaler.
        With more than a decade of experience, we provide a range of products and services:
        gold jewelry trade, bullion trade and gold refinery. Jib Hui is the first gold distributor in Thailand
        to receive gold quality guarantee certificate from the Office of the Consumer Protection Board.
        This honourable award demonstrates our commitment in serving our customers with integrity.
      </p>
      <p style="font-size:16px" class="padding-xl fw-normal thai">ห้างทองจิบฮุย เราคือร้านค้าส่งทองที่มีประสบการณ์มากกว่า 50 ปี

ห้างทองจิบฮุยมีธุรกิจที่ประสบความสำเร็จมายาวนาน
และถือว่าเป็นห้างทองรุ่นเเรกที่ได้รับเกียรติการรับใบประกาศนียบัตรจากสคบ. จึงมั่นใจได้ถึงในคุณภาพของสินค้าทองรูปพรรณจากห้างทองจิบฮุย

ด้วยความเป็นผู้เชี่ยวชาญในวงการค้าส่งทองคำ ห้างทองจิบฮุยจัดจำหน่ายและรับซื้อทองรูปพรรณ จึงได้รับความไว้วางใจจากร้านทองค้าปลีกทั่วประเทศไทย
      </p>
    </div>

<footer>

    <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
      <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
    </div>
</footer>
