import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, Query} from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductGalleryService {
  public imagesGalleryCollection: AngularFirestoreCollection;
  public query: Query;

  designLists = [];
  constructor(private afAuth: AngularFireAuth,
              private db: AngularFirestore, private firebase: AngularFireStorage) { }

  testinsert() {


let menu = [

  {
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_017.jpg?alt=media&token=ed095f06-ab5b-4d6b-8a63-1890f78edd2a'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_021.jpg?alt=media&token=7bea010b-de2e-4d98-b65b-1c6b4c724e39'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_022.jpg?alt=media&token=09e9c7ec-2d42-4bf0-8da6-31276447d56a'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_024.jpg?alt=media&token=35d1766e-0b3a-4531-a762-d591420cb57a'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_026.jpg?alt=media&token=9de78546-7d04-4c3d-baa4-bdd51cbb9cb7'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_027.jpg?alt=media&token=d2cbc78c-4091-4422-9915-ff2eaa87fcc8'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_032.jpg?alt=media&token=eeb4e427-65dd-47cc-8150-bde455c36c2a'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_033.jpg?alt=media&token=ee64a3fc-5c62-4887-a30e-67f6ab432bf3'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_034.jpg?alt=media&token=2e8d37cf-c681-464e-93c7-9ec40a8363df'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_035.jpg?alt=media&token=025d8e50-c2fd-4bcf-ba13-4cffe566bcd6'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_038.jpg?alt=media&token=0d6f4c43-06de-448a-857c-9f10d2acecc6'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_039.jpg?alt=media&token=dfeaea4a-771c-42a0-8728-68fb689a6a1b'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_041.jpg?alt=media&token=412255f6-f8e0-4a98-8155-df893dfa0e7f'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_042.jpg?alt=media&token=636a645a-5d47-4730-9244-b0a50331229a'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_043.jpg?alt=media&token=3c815795-bbd8-4dd6-85bc-1dd8ff3f8caa'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_044.jpg?alt=media&token=0501fb46-40fa-4fa3-9b89-cc626427ddd5'
},
{
 designcat: '0001',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_045.jpg?alt=media&token=559e04ba-dcdb-4c9f-9bbd-76053ac888fd'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_049.jpg?alt=media&token=0da307d8-35e4-484a-8cc4-027ecd77d62b'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_050.jpg?alt=media&token=739f217a-c914-46b3-90ea-63e6007b8131'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_051.jpg?alt=media&token=b9ac6c00-acc1-4b0b-aca6-49565f56ad19'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_052.jpg?alt=media&token=18876942-7734-4a93-974b-0167bfceb26a'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_053.jpg?alt=media&token=d19b9f31-4a6b-4cda-83e8-1e4a88b8694e'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_054.jpg?alt=media&token=ef36cbf4-8194-4d9a-89bc-89048f8b91cd'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_055.jpg?alt=media&token=6e2943da-34f2-4910-8976-84da9f3890d7'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_056.jpg?alt=media&token=02d20d0d-db06-4c2f-9ee4-1dc1f8a935a4'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_057.jpg?alt=media&token=29f3459e-4b95-4fc6-86a9-bbd02c492616'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_058.jpg?alt=media&token=8d674843-48c1-4174-9584-16c8eb8ac37e'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_059.jpg?alt=media&token=c2af5ffb-8f5b-4a21-9bcc-786703fbfa3b'
},
{
 designcat: '0005',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_060.jpg?alt=media&token=840aa2a2-4bb8-469f-9059-78d0940ff677'
},
{
 designcat: '0003',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_062.jpg?alt=media&token=c8d6270f-37c4-400e-9886-488ae0119882'
},
{
 designcat: '0003',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_063.jpg?alt=media&token=c164849b-f3bd-401f-88e3-15a759612125'
},
{
 designcat: '0003',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_064.jpg?alt=media&token=daee9064-3047-4acf-a02e-0f880c92f7bf'
},
{
 designcat: '0003',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_065.jpg?alt=media&token=947c6f2b-85d2-4020-9058-7bff9b34a196'
},
{
 designcat: '0003',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_066.jpg?alt=media&token=9e4e9d19-0c61-4a8a-b651-0cda98abc50f'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_067.jpg?alt=media&token=9b60653c-68e1-48b9-b1cb-fb61a98544cc'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_068.jpg?alt=media&token=d1ea265d-1ad3-4a5e-af68-443416843d0a'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_069.jpg?alt=media&token=0d97bb5a-ffd7-43d0-ae63-4aabafac3531'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_071.jpg?alt=media&token=7d40e7e8-860e-49c1-a4ea-00e6a55e5fb8'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_072.jpg?alt=media&token=4313079c-f172-4480-8fb4-63866eec9d1f'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_073.jpg?alt=media&token=0a658591-840e-4c88-bae2-77a2aa6fa2ff'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_074.jpg?alt=media&token=1387906a-5f86-4ee4-9c74-1cf521fcdb6b'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_075.jpg?alt=media&token=1cff7e79-8785-4a4c-a3ca-57e8681b5215'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_076.jpg?alt=media&token=3fe4e1d7-0feb-4139-93c5-f2b6455c7811'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_077.jpg?alt=media&token=d9c275f8-53e4-4b6f-958c-57268744260a'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_079.jpg?alt=media&token=fb57957b-9fad-4070-ac7f-aebf982f0a4c'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_080.jpg?alt=media&token=ff6a1c78-87cb-4c8f-9b76-a63f8e8e7fff'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_081.jpg?alt=media&token=ad5c5cdf-3479-4d4c-aba7-7efb1fbd8604'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_082.jpg?alt=media&token=f453782a-edc2-4132-8976-192ea9e31a24'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_083.jpg?alt=media&token=5724a78b-4ce0-44c5-9927-aa2a2f843ec8'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_085.jpg?alt=media&token=0f04c77b-7031-4aea-8043-8d6a4baf99ca'
},
{
 designcat: '0002',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_086.jpg?alt=media&token=9b1ac900-0b14-4392-b3f1-907ae909ff35'
},
{
 designcat: '0006',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_088.jpg?alt=media&token=3d2e3707-c54f-48bd-9cc7-bfd4e6c09a14'
},
{
 designcat: '0006',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_089.jpg?alt=media&token=a16e7931-f197-4fbd-b1e8-73e70921cad8'
},
{
 designcat: '0006',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_090.jpg?alt=media&token=066801fa-e20a-495b-9b22-bd9c01947452'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_091.jpg?alt=media&token=45723f5e-fa43-4df4-921b-ceba014c610d'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_092.jpg?alt=media&token=5dcf978b-d1c5-46ed-a6d9-75c1892d86ca'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_094.jpg?alt=media&token=58f39b18-7ec4-42b5-b224-95c50b5ad501'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_095.jpg?alt=media&token=1a3ceb99-e334-40a8-b6ed-590b4e755117'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_096.jpg?alt=media&token=9bba7165-0576-445e-b266-f7bd1bb194c0'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_097.jpg?alt=media&token=84b0961d-4cde-4eb3-b543-68f9fa921f9d'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_098.jpg?alt=media&token=ae22575e-c206-465d-bc83-0727ffc82644'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_099.jpg?alt=media&token=167578ec-0e39-4881-b632-bd2d15c9e5ca'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_101.jpg?alt=media&token=6e1fd8fb-2ae8-4d35-b46c-6779ff773c72'
},
{
 designcat: '0004',
 designid: '',
 designname: '',
 url: 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/products_img%2FJH_102.jpg?alt=media&token=56bbc7f1-c5f8-469e-ad63-15f7cd28a041'
}

 ];
menu.forEach(obj => {
   this.db.collection('designs').add(
     {
        designid: obj.designid,
        designcat: obj.designcat,
        designname: obj.designname,
        url: obj.url
    }
   );
 });

  }

  getDesignsDetailQueryByDesignCatID(designCatID:string){
    this.query = this.db.collection('designs').ref.where('designcat', '==', designCatID);
  }

  getNecklaceDesignsDetailQuery(){
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0001');
  }

  getWristBandsDesignsDetailQuery() {
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0002');

  }
  getBraceletsDesignsDetailQuery() {
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0003');

  }
  getRingsDesignsDetailQuery() {
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0004');

  }
  getPendantsDesignsDetailQuery() {
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0005');

  }
  getEarringsDesignsDetailQuery() {
    this.query = this.db.collection('designs').ref.where('designcat', '==', '0006');

  }
}
