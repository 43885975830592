import { Injectable } from '@angular/core';
import { AngularFirestore, Query, DocumentData } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RtStockService {

  constructor(private db: AngularFirestore, ) { }
    public documentData: DocumentData;

    getRTStockDocumentData(){
      return this.documentData = this.db.collection('rt_stock').doc('general_stock').ref.get();
    }

}
