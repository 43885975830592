<div class="container-fluid  mt-0">
  <div class="title div-page-title">
    <h1>Home</h1>
  </div>
  <div class="row justify-content-center mt-0 rounded" style="background-color: #F2EFF2">
    <ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
      <ng-template ngbSlide *ngFor="let img of images; index as i">
        <div class="carousel-inner">
          <div class="carousel-caption">

          </div>
          <a href="https://www.google.fr/?q=Number+{{i+1}}" target="_blank" rel="nofollow noopener noreferrer">
            <div class="d-block w-100">
              <img class="img-fluid rounded" style="max-height: 725px" alt="Responsive image" [src]="img" alt="My image {{i + 1}} description">
            </div>
          </a>
        </div>
      </ng-template>
    </ngb-carousel>

  </div>
  <div class=" mb-5 mt-5  justify-content-center">
    <div class="row mb-3">
      <div class="col-12" style="background-color: #800020">
        <h3 class="font-weight-bold d-flex justify-content-center" style="color:white ">96.5% Gold Jewelry</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0006'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-11.png?alt=media&token=03fb9db6-8d59-4681-8cd7-54388506f070" />
          </a>
        </div>
      </div>
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0003'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-13.png?alt=media&token=41789ec4-6bfb-452e-8ca8-5b4db516213a" />
          </a>
        </div>
      </div>
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0001'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-21.png?alt=media&token=3ffdaf4d-6f1c-4950-b504-16db991a4592" />
          </a>
        </div>
      </div>
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0002'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-6.png?alt=media&token=ce613e0d-7c6a-4378-8fbc-1bc2fc469e80" />
          </a>
        </div>
      </div>
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0005'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-7.png?alt=media&token=b671eda6-2baa-4aaa-a566-4d75ad9d4afa" />
          </a>
        </div>
      </div>
      <div class="col-sm-2 d-flex">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0004'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-9.png?alt=media&token=c418bb0f-d0cd-45ce-9b5b-85b8220bb408" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 mt-5 mb-5" style="height:300px">
    <iframe id="tradingview_1b674"
      src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_1b674&amp;symbol=FX_IDC%3AXAUUSD&amp;interval=60&amp;saveimage=1&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;hideideas=1&amp;theme=White&amp;style=1&amp;timezone=Asia%2FBangkok&amp;showpopupbutton=1&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;showpopupbutton=1&amp;locale=th&amp;utm_source=www.intergold.co.th&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=FX_IDC%3AXAUUSD"
      style="width: 100%; height: 100%; margin: 0 !important; padding: 0 !important;" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen=""></iframe>
  </div>
  <div class=" mb-5  justify-content-center" style="background-color: #F2EFF2">

    <div class="row">
      <div class="col-sm-6 d-flex">
        <div class="mx-auto">
          <div class="card card-body h-100  justify-content-center flex-fill" style="background-color: #800020; max-width: 300px">

              <div class="row">
                <div class="col-12  border-white border-top border-bottom border-left border-right " style="background-color: #800020">
              <h3 class="font-weight-bold d-flex justify-content-center thai mt-3 mb-3" style="color: #FFDF00; ">ราคาทองคำแท่ง</h3>
              </div>
              </div>
              <div class="row">
                <div class="col-sm-6 ">
                  <div class="row ">
                    <div class="col-12 d-flex justify-content-center border border-white " style="background-color: #FFCC99">
                      <label class="h4 font-weight-bold thai mt-2 mb-2" style="color: black ; font-weight: bold; background-color: #FFCC99">ลูกค้าขาย</label>
                    </div>
                    <div class="col-12 d-flex justify-content-center border border-white" style="background-color: #D9D9D9">
                      <label class="h3 font-weight-bold mt-2 mb-2" style="color: black">{{assocbuygold965}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center border border-white" style="background-color: #FFCC99">
                      <label class="h4 font-weight-bold thai  mt-2 mb-2" style="color: black ; font-weight: bold; background-color: #FFCC99">ลูกค้าซื้อ</label>
                    </div>
                    <div class="col-12 d-flex justify-content-center border border-white" style="background-color: #D9D9D9">
                      <label class="h3 font-weight-bold mt-2 mb-2" style="color: black">{{assocsellgold965}}</label>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="d-flex  mt-0  mx-auto justify-content-center">
          <img class="img-thumbnail" alt="Responsive image" style="max-height: 300px" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fbanner_final.jpg?alt=media&token=f537da0e-e452-434c-a2b5-0e1c3f258017" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 justify-content-center">
      <div class="d-flex w-100 justify-content-center mb-5">
        <img class="img-fluid rounded" style="max-height: 150px" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FidTxt1et_400x400.jpg?alt=media&token=ae9f7f3e-7444-46e6-b0f3-b7c514bbb846">
      </div>
    </div>
    <div class="col-sm-6">
      <div class="d-flex w-100 justify-content-center mb-5">
        <img class="img-fluid rounded" style="max-height: 150px" alt="Responsive image"
          src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fnews_lSdeHGwtbm194349_533.jpg?alt=media&token=547150d4-cb65-488d-a3c8-ac01ada17bed">
      </div>
    </div>
  </div>

</div>
<footer>

  <div class="d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
    <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.
    <p>
  </div>
</footer>
