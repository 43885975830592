
<body>
  <div class="title div-page-title mb-0">
    <h1 class="mb-0">Our Solutions</h1>
  </div>
  <div class="col-12" style="background:#F5F5F5">
  <div class="row mt-0">
    <div class="col col-xs-12" >

  <div class="row mt-4 mb-4">
    <div class="col-md-4">
      <div class="card" >
        <img class="img-fluid rounded " alt="Responsive image"  src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fx20200515_104831.jpg?alt=media&token=bcc84661-b4b4-44ad-a9ed-5bb4819b6332"/>
        <div class="card-body">
          <div class="row">
          <div class="col">
            <h4 style="">Gold Jewelry 96.5%</h4>
          </div>
          </div>
          <p class="card-text">With more than 2,000 designs at our disposal, we have been offering high quality 96.5% gold Jewelery to gold shops around Thailand for more than 50 years.</p>
          <a  href="#" routerLink="/gold965jewelery" >View Products</a>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card" >
        <img class="img-fluid rounded" alt="Responsive image"  src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fx20200514_150535.jpg?alt=media&token=03f0cc63-5430-4360-a10a-f580e7ccb363"/>
        <div class="card-body">
          <h4 style="">Gold Scrap Buying</h4>
          <p class="card-text">We buy old gold jewelry and scraps at negotiable rate. Our steady focus, integrity and exceptional service help our clients to capture high efficiency in this low margin business</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card" >
        <img class="img-fluid rounded " alt="Responsive image"  src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fx20200515_105232(0).jpg?alt=media&token=479e3015-8777-4c26-a941-6161d47986c2"/>
        <div class="card-body">
          <div class="row">
          <div class="col">
            <h4 style="">Gold Bullion Dealing</h4>
          </div>
          </div>
          <p class="card-text">With integrity and commitment, we provide physical gold dealing services to our clients for both 96.5% purity gold and 99.99% purity gold.</p>
        </div>
      </div>
    </div>
  </div>


  </div>
  </div>
  </div>
</body>
<footer>

    <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
      <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
    </div>
</footer>
