<div class="title div-page-title">
  <h1>Real-Time Stock</h1>
</div>

    <div class = "container-fluid">
      <div class="float-left"><h6> {{errorMessage}}</h6></div><br>
      <div class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollEnd()">

        <div class="row">

          <div class="col-xl-8" *ngFor="let rsStock of listOfData| slice:0:limit">
            <div class="card border-white" >
              <a href="#" [routerLink]="" >
                <img [src] = "rsStock.jpg.downloadURL" class="img-fluid" alt="Responsive image"/>
              </a>

              <div class="card-body"  >
                <p class="card-text">Updated time: {{rsStock.jpg.updatedTime.toDate()| date: 'dd/MM hh:mm'  }}</p>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                  </div>
                  <small class="text-muted"></small>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="d-flex justify-content-center bg-light" *ngIf="isloading"> loading
    </div>
    </div>
    <footer>

        <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
          <p style="font-size:15px; color:white">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
        </div>
    </footer>
