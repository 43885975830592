import { Component, OnInit } from '@angular/core';
import { DocumentData, Query, QuerySnapshot, AngularFirestore } from 'angularfire2/firestore';
import { Country, State, City } from 'country-state-city';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import myData from '../../assets/data/district_th.json';
import myCountry from '../../assets/data/country_th.json';
interface IDType {
id: String;
description: String;
}

@Component({
  selector: 'app-registration-th',
  templateUrl: './registration-th.component.html',
  styleUrls: ['./registration-th.component.scss']
})
export class RegistrationThComponent implements OnInit {
  isDisabledOtherOccupation = true;
  isDisabledOtherObjective = true;
  titleList = [
    { id: '01', name: 'นาย' },
    { id: '02', name: 'นาง' },
    { id: '03', name: 'นางสาว' },
  ]
  objectiveList = [
    { id: '01', description: 'เก็งกําไร' },
    { id: '02', description: 'เพื่อใช้สำหรับเป็นของขวัญ' },
    { id: '03', description: 'เพื่อค้าขาย' },
    { id: '04', description: 'เพื่อใช้ในการส่วนตัว' },
    { id: '99', description: 'อื่นๆ' },
  ];
  occupationList = [
    { id: '1', description: 'เจ้าของกิจการ' },
    { id: '2', description: 'ราชการ' },
    { id: '3', description: 'รับจ้าง' },
    { id: '4', description: 'พนักงานรัฐวิสาหกิจ' },
    { id: '5', description: 'อาชีพอิสระ' },
    { id: '6', description: 'นิสิต นักศึกษา' },
    { id: '7', description: 'พนักงานบริษัท' },
    { id: '99', description: 'อื่นๆ' }
  ];

  idTypeList: IDType[] = [
    { id: '1', description: 'บัตรประจำตัวประชาชน' },
    { id: '2', description: 'บัตรข้าราชการ' },
    { id: '3', description: 'บัตรประจำตัวผู้เสียภาษี' },
    { id: '4', description: 'หนังสือเดินทาง' },
    { id: '5', description: 'ใบสำคัญคนต่างด้าว' },
    { id: '6', description: 'บัตรที่ออกโดยองค์กรรัฐ' },
  ];


  stateList = null;
  selectedCountry;
  selectedState;
  selectedSubDistrict;
  selectedDistrict;
  selectedZipCode;
  countriesList = myCountry;
  constructor(private db: AngularFirestore) {

   }

  private phoneCountryCode = '66';
  isSubmissionCompleted = false;

  isLoading = false;
  ngOnInit(): void {
    //const provinces = myData.map(data => data.province);
    const provinces = myData;
    console.log(provinces);
    this.stateList = provinces.filter((x, i, a) => i === a.findIndex(
      (t) => (
        t.province === x.province
    )));

    console.log(this.stateList);
    this.isSubmissionCompleted = false;
  }
  onCountryChange($event) {
    this.phoneCountryCode = $event.dialCode;

  }
  idSelect(value:IDType){
    console.log(value.id);
  }

  onSubmit(f: NgForm) {
    this.isLoading = true;
    f.value.phoneCountryCode = this.phoneCountryCode;

    if(typeof this.selectedSubDistrict === 'object') {
	     f.value.subDistrict = this.selectedSubDistrict.district;
     }else{
       f.value.subDistrict = this.selectedSubDistrict;
     }
     console.log(f.value);
    const form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      this.db.collection('registration').add({
        value: f.value,
        createdTime: firebase.firestore.FieldValue.serverTimestamp(),
        flag: '0'
      })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          this.isSubmissionCompleted = true;
          this.isLoading = false;

        })
        .catch((error) => {
          this.isLoading = false;
          console.error('Error adding document: ', error);

        });

    }

  }

  validate() {
    const form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    }
    form.classList.add('was-validated');
  }

  objectiveSelect(value) {
    if (value == 99) {
      this.isDisabledOtherObjective = false;
    } else {
      this.isDisabledOtherObjective = true;
    }
  }
  countrySelect(isoCode) {
    console.log(isoCode);
    this.stateList = State.getStatesOfCountry(isoCode);

  }
  stateSelect(stateCode) {

  }
  onSelectSubDistrict(value) {
    console.log(value);
    this.selectedDistrict = value.item.amphoe;
    this.selectedState = value.item.province;
    this.selectedZipCode = value.item.zipcode;
    this.selectedCountry = "TH";

  }
  onSelectDistrict(value) {

    this.selectedCountry = "ประเทศไทย";
    this.selectedState = value.item.province;
  }
  occupationSelect(value) {

    if (value == 99) {
      this.isDisabledOtherOccupation = false;
    } else {
      this.isDisabledOtherOccupation = true;
    }

  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : myData.filter(v => v.district.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (x: {district: string}) => x.district;

}
