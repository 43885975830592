import { Injectable } from '@angular/core';
import {AngularFirestore,   DocumentReference} from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireStorage } from 'angularfire2/storage';
@Injectable({
  providedIn: 'root'
})
export class GoldpriceService {
  public documentref: DocumentReference;
  constructor(private db: AngularFirestore) { }

  getGoldPriceQuery(){
    this.documentref = this.db.collection('goldprice').ref.doc('price');
  }
}
