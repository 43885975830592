<div class="title div-page-title">
  <h1>Registration</h1>
</div>

<div class="container-fluid mb-5 " style="background-color: #F5F5F5">

  <div class="row">
    <div class="col mb-5 mt-5" height="80%" [hidden]="!isSubmissionCompleted">
      <div class="card  mx-auto text-center" style="width: 18rem;">
        <div class="card-body">
          <i class="bi bi-check-circle-fill h1" style="color:green"></i>
          <p class="card-subtitle font-weight-bold h2 text-muted mb-2">Thank you.</p>
          <p class="font-weight-bold h4 text-muted ">Your submission is complete!</p>

        </div>
      </div>

    </div>

  </div>

  <div class="row justify-content-center mt-4">

    <div class="col-md-8 order-md-1  " [hidden]="isSubmissionCompleted">
      <form name="register_form" class="needs-validation" #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
        <div class="form-row">
          <div class="col-md-2 mb-3">

            <label class="control-label fw-normal" for="title">Title</label>

            <select class="custom-select fw-normal" name="title" ngModel #title="ngModel" required>

              <option value="" class="fw-normal">Choose...</option>
              <option *ngFor="let title of titleList" [value]="title.name">{{ title.name }}</option>

            </select>
            <div class="invalid-feedback">
              <p class="fw-normal">Please provide us with your title<p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="control-label fw-normal" for="firstName">First name</label>
            <input type="text" class="form-control" name="firstName" ngModel #firstNameRef="ngModel" placeholder="" required>

            <div *ngIf="firstNameRef.errors && (firstNameRef.dirty || firstNameRef.touched)" class="alert alert-danger">
              <div [hidden]="!firstNameRef.errors.required">
                <p class="fw-normal">Please enter your first name.<p>
              </div>

            </div>
            <div class="invalid-feedback ">
              <p class="fw-normal">Valid First name is required.<p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="middleName" class="fw-normal">Middle Name</label>
            <input type="text" class="form-control" name="middleName" ngModel #middleName="ngModel" placeholder="" value="">
            <div class="invalid-feedback">
              <p class="fw-normal">Valid middle name is required.<p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastName" class="fw-normal">Last name</label>
            <input type="text" class="form-control" name="lastName" ngModel #lastName="ngModel" placeholder="" value="">
            <div class="invalid-feedback">
              Valid last name is required.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-3">

            <label class="control-labe fw-normal" for="nationality">Country of citizenship</label>

            <select class="custom-select fw-normal" name="nationality" ngModel #nationality="ngModel" required>

              <option value="">Choose...</option>
              <option *ngFor="let country of countriesList" [value]="country.isoCode">{{ country.name }}</option>

            </select>
            <div class="invalid-feedback">
              <p class="fw-normal">Please provide us with your country of citizenship.<p>
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <label class="control-label fw-normal" for="dateOfBirth">Date of birth</label>
            <div class="row">
              <div class="col-md-9 mb-3">
                 <div class="input-group">
                <input class="form-control disabled-input" placeholder="yyyy-mm-dd" name="dateOfBirth" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" ngModel #dateOfBirth="ngModel" required>
                <div class="input-group-append">
                  <button class="bi bi-calendar" style="color: #800020; background-color: #E2D0CF" (click)="d.toggle()" type="button" minViewMode="years"></button>
                </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="col-md-4 mb-3">

            <label class="control-label fw-normal" for="idType">ID Type</label>
            <select class="custom-select d-block w-100 fw-normal" name="idType" ngModel #idType="ngModel" required (change)="idSelect($event.target.value)">
              <option value="">Choose...</option>
              <option *ngFor="let idType of idTypeList" [value]="idType.id">{{ idType.description }}</option>
            </select>
            <div class="invalid-feedback fw-normal">
              Please provide us with the type of your identification.
            </div>

          </div>

          <div class="col-md-4 mb-3">

            <label class="control-label fw-normal" for="idNo">Identification No</label>
            <input type="text" class="form-control" required name="idNo" ngModel #idNo="ngModel" required>
            <div class="invalid-feedback fw-normal">
              Please enter your identification number.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="control-label fw-normal" for="address">Address Line 1</label>
          <input type="text" class="form-control" required name="address" ngModel #address="ngModel">
          <div class="invalid-feedback fw-normal">
            Please enter your address.
          </div>
        </div>
        <ng-template #rt let-r="result" let-t="term">

            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>

        </ng-template>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label  for="subDistrict" class="fw-normal">Address Line 2</label>
            <input type="text" class="form-control" name="subDistrict" ngModel #subDistrict="ngModel" placeholder="" value="" >

          </div>
          <div class="col-md-6 mb-3">
            <label class="control-label fw-normal" for="district">District</label>
            <input  type="text" class="form-control" name="district" ngModel #district="ngModel" placeholder="" value="" required [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="onSelectDistrict($event)"/>
            <div class="invalid-feedback">
              Valid district is required.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 mb-3">
            <label class="control-label fw-normal" for="country">Country</label>

            <select class="custom-select d-block w-100 fw-normal" name="country" ngModel #country="ngModel" required (change)="countrySelect($event.target.value)" [(ngModel)]="selectedCountry" >
              <option selected value="">Choose...</option>
              <option *ngFor="let country of countriesList" [value]="country.isoCode">{{ country.name }}</option>

            </select>
            <div class="invalid-feedback fw-normal">
              Please select a valid country.
            </div>
          </div>
          <div class="col-md-4 mb-3 fw-normal">
            <label class="control-label" for="state">State</label>
            <select class="custom-select d-block w-100" name="state" ngModel #state="ngModel" required (change)="stateSelect($event.target.value)" [(ngModel)]="selectedState">
              <option value="">Choose...</option>
              <option *ngFor="let state of stateList" [value]="state.name">{{ state.name }}</option>
            </select>
            <div class="invalid-feedback">
              Please provide a valid state.
            </div>
          </div>
          <div class="col-md-3 mb-3 fw-normal">
            <label class="control-label" for="zip">Zip</label>
            <input type="text" class="form-control" name="zip" ngModel #zip="ngModel" placeholder="" required>
            <div class="invalid-feedback">
              Zip code required.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3 fw-normal">
            <label for="email">Email <span class="text-muted">(Optional)</span></label>
            <input type="email" class="form-control" name="email" ngModel #email="ngModel" placeholder="you@example.com">
            <div class="invalid-feedback">
              Please enter a valid email address.
            </div>
          </div>
          <div class="col-md-6 mb-3 fw-normal">
            <label class="control-label" for="phoneNo">Phone Number</label>
            <div>
              <div>
                <input (countryChange)="onCountryChange($event)" type="text" class="form-control" name="phoneNo" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'th', preferredCountries: ['th','in','bd']}" ngModel #phoneNo="ngModel" required />
              </div>
              <div class="invalid-feedback">
                Please enter a valid phone number.
              </div>
            </div>
          </div>

        </div>


        <div class="row">
          <div class="col-md-4 mb-3 fw-normal">
            <label class="control-label" for="occupation">Occupation</label>
            <select class="custom-select d-block w-100" name="occupation" (change)="occupationSelect($event.target.value)" ngModel #occupation="ngModel" required>
              <option selected value="">Choose...</option>
              <option *ngFor="let occupation of occupationList" [value]="occupation.id">{{ occupation.description }}</option>

            </select>
            <div class="invalid-feedback">
              Please provide occupation detail.
            </div>
          </div>
          <div class="col-md-4 mb-3 fw-normal" >
            <label for="otherOccupation">If other</label>
            <input type="text" class="form-control" name="otherOccupation" ngModel #otherOccupation="ngModel" required placeholder="" value="" [readonly]="isDisabledOtherOccupation">

          </div>
          <div class="col-md-4 mb-3 fw-normal">
            <label for="workPlace">Work Place (optional)</label>
            <input type="text" class="form-control" name="workPlace" ngModel #workPlace="ngModel" placeholder="" value="">
          </div>
        </div>
        <div class="row fw-normal">
          <div class="col-md-8 mb-3">
            <label class="control-label" for="objective">Which of the following best describes your objective for purchasing precious metal?</label>
            <select class="custom-select d-block w-100" name="objective" ngModel #objective="ngModel" (change)="objectiveSelect($event.target.value)" required>
              <option value="">Choose...</option>
              <option *ngFor="let objective of objectiveList" [value]="objective.id">{{ objective.description }}</option>
            </select>
            <div class="invalid-feedback">
              Please provide us with your objective for purchasing precious metal.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="otherObjective">If other</label>
            <input type="text" class="form-control" name="otherObjective" required ngModel #otherObjective="ngModel" placeholder="" value="" [readonly]="isDisabledOtherObjective">

          </div>
        </div>
        <div class="row justify-content-center fw-normal">

          <div class="col-sm-4">
            <button class="btn btn-primary btn-lg btn-block " type="submit" (click)="validate()" style="background-color: #800020;">
              <div [hidden]="!isLoading">
                <span class="spinner"></span>Please wait...
              </div>
              <span [hidden]="isLoading">Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<footer>

  <div class="d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
    <p style="font-size:15px; color:white" class ="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.
    <p>
  </div>
</footer>
