<div class="title div-page-title shadow-lg">
  <h1>96.5% Gold Jewelry</h1>
</div>
<div class="container-fluid" style="background-color: #F5F5F5">
  <nav class="navbar navbar-expand-lg navbar-light  navbar-default">
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <ng-template [ngIf]="isMenuCollapsed">
      <a class="navbar-brand" [routerLink]="'.'">{{category}}</a>
    </ng-template>
    <!--<div class="collapse navbar-collapse" id="navbarNav">-->
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav" mx-auto>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryNecklaces()" routerLinkActive="navbar-link-active">สร้อยคอ / Necklaces</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryWristbands()">สร้อยมือ / Wristbands</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryBracelets()">กำไล / Bracelets</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryRings()">แหวน / Rings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryPendants()">จี้ / Pendants</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="" (click)="queryEarrings()">ต่างหู / Earrings</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="container-fluid">

  <div class="search-results" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollEnd()">

    <div class="row">

      <div class="col-md-4" *ngFor="let design of designList">
        <div class="card mb-4 border-white">
          <a href="#" [routerLink]="" (click)="showImage(design.url)">
            <img [src]="design.url" class="img-fluid" alt="Responsive image" />
          </a>

          <div class="card-body">
            <p class="card-text"></p>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group">
              </div>
              <small class="text-muted"></small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center bg-light" *ngIf="isloading"> loading
  </div>
</div>
<footer>

  <div class="d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
    <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.
    <p>
  </div>
</footer>
