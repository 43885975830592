import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';

@Injectable({
  providedIn: 'root'
})
export class UsermngmtService {

  constructor(public afAuth: AngularFireAuth) { }

    validateUserLogOnStatus(user: firebase.User): boolean{
      if (user) {
    // User is signed in.
        user.getIdTokenResult().then(
          (getIdTokenResult) => {
            if (getIdTokenResult.claims.isCustomer){
              return true;
            }else{
              return false;
            }
          }
        );
      } else {
        return false;
      }


  }

}
