import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase/app';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth, private router: Router, private route: ActivatedRoute) { }
  public message: String;
  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
           this.message = param.message;
       });
  }

  backToPreviousPage() {
    this.route.queryParams.subscribe(param => {
           this.router.navigate(param.redirectURL);
       });
  }

  onLoginGoogle(){
    const provider = new auth.GoogleAuthProvider();
    this.afAuth.auth.signInWithPopup(provider).then(
      (userCredential: auth.UserCredential) => {
        this.backToPreviousPage();
        console.log(userCredential.user);
        return userCredential.user;
      }
    );
  }

  onLoginYahoo(){
    const provider = new auth.OAuthProvider('yahoo.com');
    this.afAuth.auth.signInWithPopup(provider).then(
      (userCredential: auth.UserCredential) => {
        this.backToPreviousPage();
        return userCredential.user;
      }
    );
  }
  onLoginFacebook(){
    const provider = new auth.FacebookAuthProvider();
    this.afAuth.auth.signInWithPopup(provider).then(
      (userCredential: auth.UserCredential) => {
        this.backToPreviousPage();
        return userCredential.user;
      }
    );

  }

}
