import { Component, OnInit} from '@angular/core';
import { DocumentData, Query, QuerySnapshot, AngularFirestore } from 'angularfire2/firestore';
  import { Country, State, City } from 'country-state-city';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';


interface IDType {
  id: String;
  description: String;
}
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  isDisabledOtherOccupation = true;
  isDisabledOtherObjective = true;
  titleList = [
    { id: '01', name: 'Mr' },
    { id: '02', name: 'Ms' },
  ]
  objectiveList = [
    { id: '01', description: 'Speculation' },
    { id: '02', description: 'As a gift to someone' },
    { id: '03', description: 'For trade as a business' },
    { id: '04', description: 'For personal use' },
    { id: '99', description: 'Other' },
  ];
  occupationList = [
    { id: '1', description: 'Business owner' },
    { id: '2', description: 'Civil servant' },
    { id: '3', description: 'Contractor' },
    { id: '4', description: 'State enterprise employee' },
    { id: '5', description: 'Freelance' },
    { id: '6', description: 'Student' },
    { id: '7', description: 'Company employee' },
    { id: '99', description: 'Other' }
  ];

  idTypeList: IDType[] = [
    { id: '1', description: 'Thai citizen ID card' },
    { id: '2', description: 'Thai state employee card' },
    { id: '3', description: 'Tax identification card' },
    { id: '4', description: 'Passport' },
    { id: '5', description: 'Alien identification card' },
    { id: '6', description: 'Card issued by a government organization' },
  ];

  addressTest;
  stateList = null;
  selectedCountry;
  selectedState;
  countriesList = Country.getAllCountries();

  constructor(private db: AngularFirestore
  ) {

  }
  private phoneCountryCode = '66';
  isSubmissionCompleted = false;

  isLoading = false;
  ngOnInit(): void {
    this.isSubmissionCompleted = false;
  }
  onCountryChange($event) {
    this.phoneCountryCode = $event.dialCode;

  }
  idSelect(value: IDType) {
  }

  onSubmit(f: NgForm) {
    this.isLoading = true;
    f.value.phoneCountryCode = this.phoneCountryCode;
    f.value.state = this.selectedState;
    const form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      this.db.collection('registration').add({
        value: f.value,
        createdTime: firebase.firestore.FieldValue.serverTimestamp(),
        flag: '0'
      })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          this.isSubmissionCompleted = true;
          this.isLoading = false;

        })
        .catch((error) => {
          this.isLoading = false;
          console.error('Error adding document: ', error);

        });

    }

  }

  validate() {
    const form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    }
    form.classList.add('was-validated');
  }

  objectiveSelect(value) {
    if (value == 99) {
      this.isDisabledOtherObjective = false;
    } else {
      this.isDisabledOtherObjective = true;
    }
  }
  countrySelect(isoCode) {
    console.log(isoCode);
    this.stateList = State.getStatesOfCountry(isoCode);
    console.log(this.stateList);
  }
  stateSelect(stateCode) {

  }
  onSelectDistrict(value) {

    this.selectedCountry = value.item.countryCode;
    this.stateList = State.getStatesOfCountry(value.item.countryCode);

    this.selectedState = value.item;
  }
  occupationSelect(value) {

    if (value == 99) {
      this.isDisabledOtherOccupation = false;
    } else {
      this.isDisabledOtherOccupation = true;
    }

  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // map(term => term.length < 2 ? []
      //  : City.getAllCities().filter(v => v.name.toLowerCase().startsWith(term.toLocaleLowerCase())).splice(0, 10))
      map(term => term === '' ? []
        : City.getAllCities().filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  formatter = (x: { name: string }) => x.name;


}
