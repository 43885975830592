import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jewelery-category',
  templateUrl: './jewelery-category.component.html',
  styleUrls: ['./jewelery-category.component.scss']
})
export class JeweleryCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
