<div class="title div-page-title">
  <h1 class="thai">ลงทะเบียนลูกค้า</h1>
</div>


<div class="container-fluid mb-5" style="background-color: #F5F5F5">

  <div class="row fw-normal">
    <div class="col mb-5 mt-5" height="80%" [hidden]="!isSubmissionCompleted">
      <div class="card  mx-auto text-center" style="width: 18rem;">
        <div class="card-body">
          <i class="bi bi-check-circle-fill h1" style="color:green"></i>
          <p class="card-subtitle font-weight-bold h2 text-muted mb-2">ขอบคุณค่ะ</p>
          <p class="font-weight-bold h4 text-muted">คุณได้ลงทะเบียนเรียบร้อยแล้ว</p>

        </div>
      </div>

    </div>

  </div>

  <div class="row justify-content-center mt-4 fw-normal thai">

    <div class="col-md-8 order-md-1  " [hidden]="isSubmissionCompleted">
      <form name="register_form" class="needs-validation" #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
        <div class="form-row">
          <div class="col-md-2 mb-3">

            <label class="control-label" for="title">คำนำหน้า</label>

            <select class="custom-select " name="title" ngModel #title="ngModel" required>

              <option value="">โปรดเลือก...</option>
              <option *ngFor="let title of titleList" [value]="title.name">{{ title.name }}</option>

            </select>
            <div class="invalid-feedback">
              โปรดเลือกคำนำหน้าของท่าน
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="control-label" for="firstName">ชื่อจริง</label>
            <input type="text" class="form-control" name="firstName" ngModel #firstNameRef="ngModel" placeholder="" required>

            <div *ngIf="firstNameRef.errors && (firstNameRef.dirty || firstNameRef.touched)" class="alert alert-danger">
              <div [hidden]="!firstNameRef.errors.required">
                โปรดกรอกชื่อจริงของท่าน
              </div>

            </div>
            <div class="invalid-feedback">
              โปรดกรอกชื่อจริงของท่าน
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="middleName">ชื่อกลาง</label>
            <input type="text" class="form-control" name="middleName" ngModel #middleName="ngModel" placeholder="" value="">
            <div class="invalid-feedback">
              Valid middle name is required.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastName">นามสกุล</label>
            <input type="text" class="form-control" name="lastName" ngModel #lastName="ngModel" placeholder="" value="">
            <div class="invalid-feedback">
              Valid last name is required.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-3">

            <label class="control-label" for="nationality">สัญชาติ</label>

            <select class="custom-select " name="nationality" ngModel #nationality="ngModel" required>

              <option value="">โปรดเลือก...</option>
              <option *ngFor="let country of countriesList" [value]="country.alpha2">{{ country.name }}</option>

            </select>
            <div class="invalid-feedback">
              โปรดเลือกสัญชาติของท่าน
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <label class="control-label" for="dateOfBirth">วันเกิด</label>
            <div class="row">
              <div class="col-md-9 mb-3">
                <div class="input-group">
                  <input class="form-control disabled-input" placeholder="yyyy-mm-dd" name="dateOfBirth" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" ngModel #dateOfBirth="ngModel" required>
                  <div class="input-group-append">
                    <button class="bi bi-calendar" style="color: #800020; background-color: #E2D0CF" (click)="d.toggle()" type="button" minViewMode="years"></button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="col-md-4 mb-3">

            <label class="control-label" for="idType">ประเภทบัตรแสดงตัว</label>
            <select class="custom-select d-block w-100" name="idType" ngModel #idType="ngModel" required (change)="idSelect($event.target.value)">
              <option value="">โปรดเลือก...</option>
              <option *ngFor="let idType of idTypeList" [value]="idType.id">{{ idType.description }}</option>
            </select>
            <div class="invalid-feedback">
              โปรดเลือกประเภทบัตรแสดงตัวของท่าน
            </div>

          </div>

          <div class="col-md-4 mb-3">

            <label class="control-label" for="idNo">หมายเลขบัตรแสดงตัว</label>
            <input type="text" class="form-control" required name="idNo" ngModel #idNo="ngModel" required>
            <div class="invalid-feedback">
              โปรดกรอกหมายเลขบัตรแสดงตัวของท่าน
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="control-label" for="address">ที่อยู่</label>
          <input type="text" class="form-control" required name="address" ngModel #address="ngModel">
          <div class="invalid-feedback">
            โปรดกรอกที่อยู่ของท่าน
          </div>
        </div>
        <div class="row">
          <ng-template #rt let-r="result" let-t="term">

              <ngb-highlight [result]="r.district" [term]="t"></ngb-highlight>

              , เขต/อำเภอ {{r.amphoe}} , จังหวัด{{r.province}} , ประเทศไทย , {{r.zipcode}}
          </ng-template>
          <div class="col-md-6 mb-3">

            <label class="control-label" for="subDistrict">แขวง / ตำบล</label>
            <input type="text" class="form-control" name="subDistrict" ngModel #subDistrict="ngModel" placeholder="" value="" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="onSelectSubDistrict($event)" required [(ngModel)]="selectedSubDistrict">
            <div class="invalid-feedback">
              โปรดกรอก แขวง / ตำบล ของท่าน
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="control-label" for="district">เขต / อำเภอ</label>
            <input type="text" class="form-control" name="district" ngModel #district="ngModel" placeholder="" value="" required [(ngModel)]="selectedDistrict">
            <div class="invalid-feedback">
              โปรดกรอก เขต / อำเภอ ของท่าน
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 mb-3">
            <label class="control-label" for="country">ประเทศ</label>

            <select class="custom-select d-block w-100" name="country" ngModel #country="ngModel" required (change)="countrySelect($event.target.value)" [(ngModel)]="selectedCountry" >
              <option selected value="">โปรดเลือก...</option>
              <option *ngFor="let country of countriesList" [value]="country.alpha2">{{ country.name }}</option>

            </select>
            <div class="invalid-feedback">
              โปรดเลือกประเทศที่ท่านอยู่
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label class="control-label" for="state">จังหวัด</label>
            <input type="text" class="form-control" name="state" ngModel #state="ngModel" placeholder="" value="" required [(ngModel)]="selectedState">
            <div class="invalid-feedback">
              โปรดเลือกจังหวัดที่ท่านอยู่
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="control-label" for="zip">รหัสไปรษณีย์</label>
            <input type="text" class="form-control" name="zip" ngModel #zip="ngModel" placeholder="" required [(ngModel)]="selectedZipCode">
            <div class="invalid-feedback">
              โปรดกรอกรหัสไปรษณีย์ของท่าน
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="email">Email <span class="text-muted">(ไม่จำเป็น)</span></label>
            <input type="email" class="form-control" name="email" ngModel #email="ngModel" placeholder="you@example.com">
            <div class="invalid-feedback">
              Please enter a valid email address.
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="control-label" for="phoneNo">เบอร์โทรศัพท์</label>
            <div>
              <div>
                <input (countryChange)="onCountryChange($event)" type="text" class="form-control" name="phoneNo" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'th', preferredCountries: ['th','in','bd']}" ngModel #phoneNo="ngModel" required />
              </div>
              <div class="invalid-feedback">
                โปรดกรอกเบอร์โทรศัพท์ของท่าน
              </div>
            </div>
          </div>

        </div>


        <div class="row">
          <div class="col-md-4 mb-3">
            <label class="control-label" for="occupation">อาชีพ</label>
            <select class="custom-select d-block w-100" name="occupation" (change)="occupationSelect($event.target.value)" ngModel #occupation="ngModel" required>
              <option selected value="">โปรดเลือก...</option>
              <option *ngFor="let occupation of occupationList" [value]="occupation.id">{{ occupation.description }}</option>

            </select>
            <div class="invalid-feedback">
              โปรดเลือกอาชีพของท่าน
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="otherOccupation">หากท่านเลือกอื่นๆ โปรดกรอกอาชีพของท่าน</label>
            <input type="text" class="form-control" name="otherOccupation" ngModel #otherOccupation="ngModel" required placeholder="" value="" [readonly]="isDisabledOtherOccupation">

          </div>
          <div class="col-md-4 mb-3">
            <label for="workPlace">สถานที่ทำงาน (ไม่จำเป็น)</label>
            <input type="text" class="form-control" name="workPlace" ngModel #workPlace="ngModel" placeholder="" value="">
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 mb-3">
            <label class="control-label" for="objective">วัตถุประสงค์ในการซื้อโลหะมีค่า โปรดเลือกข้อที่ตรงกับท่านมากที่สุด</label>
            <select class="custom-select d-block w-100" name="objective" ngModel #objective="ngModel" (change)="objectiveSelect($event.target.value)" required>
              <option value="">โปรดเลือก...</option>
              <option *ngFor="let objective of objectiveList" [value]="objective.id">{{ objective.description }}</option>
            </select>
            <div class="invalid-feedback">
              โปรดเลือกวัตถุประสงค์ในการซื้อโลหะมีค่าของท่าน
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="otherObjective">หากท่านเลือกอื่นๆ โปรดระบุ</label>
            <input type="text" class="form-control" name="otherObjective" required ngModel #otherObjective="ngModel" placeholder="" value="" [readonly]="isDisabledOtherObjective">

          </div>
        </div>
        <div class="row justify-content-center ">

          <div class="col-sm-4">
            <button class="btn btn-primary btn-lg btn-block thai" type="submit" (click)="validate()" style="background-color: #800020;">
              <div [hidden]="!isLoading">
                <span class="spinner"></span>Please wait...
              </div>
              <span [hidden]="isLoading">ยืนยัน</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<footer>

  <div class="d-flex justify-content-center w-100 p-1 " style="background-color: #800020;">
    <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.
    <p>
  </div>
</footer>
