import { Component, OnInit , ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { DocumentData, Query, QuerySnapshot , DocumentReference, DocumentSnapshot} from 'angularfire2/firestore';
import { GoldpriceService } from '../services/goldprice.service';
import {EventSourcePolyfill} from 'ng-event-source';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private httpClient: HttpClient, private service: GoldpriceService) {
    this.httpClient.get('https://us-central1-jib-hui.cloudfunctions.net/java-http-function', {responseType: 'text'}).subscribe(
      (test_data: any) => { console.log(test_data); }
    );
  }
  documentRef: DocumentReference;
  assocbuygold965: number;
  assocsellgold965: number;
  // images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
/*  images = ['https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fcarousel_img41.jpg?alt=media&token=b2c6017c-85f5-42a3-8e2a-58421e17ab84',
 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fcarousel_img21.jpg?alt=media&token=8d80b442-963e-49b8-833c-3ea9a1008e57',
 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fcarousel_img31.jpg?alt=media&token=26db1364-4c4f-4f13-86d8-c7ecfa25cccc',
 'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2F20200512_1053531.jpg?alt=media&token=b6c52db4-2792-40b6-a807-849b3518af65'];*/

 images = ['https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FJIBHUI%20BANNER_aboutjibhui_72ppi.png?alt=media&token=cadc84f4-6cc8-4149-8bdb-7ed11fa2a38e',
'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FJIBHUI%20BANNER_goldbulliondealing_72ppi.png?alt=media&token=6d3cffb0-73a6-4eb3-b7e4-7b096805a662',
'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FJIBHUI%20BANNER_goldjewelry_72ppi.png?alt=media&token=3cf9ccac-eda5-4242-8a4f-50de72e3b305',
'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FJIBHUI%20BANNER_goldscrap_72ppi.png?alt=media&token=fe750267-11ba-49c2-bba6-341c5b22cbfa',
'https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2FJIBHUI%20BANNER_goldtrading_72ppi-13.png?alt=media&token=63d52111-9439-4e19-831d-3c4ea360471f'];
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  currentQuery: Query;
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  // connect(): void {
  //    let source = new EventSourcePolyfill('http://localhost:8080/stream', { });
  //      //let source = new EventSourcePolyfill('http://localhost:8080/testingget');
  //
  //        source.addEventListener('message', message => {
  //            let n: Notification; //need to have this Notification model class in angular2
  //            //n = JSON.parse(message.data);
  //            console.log(n);
  //        });
  //    }
  ngOnInit(): void {
  //    this.connect();


    this.service.getGoldPriceQuery();
    this.documentRef = this.service.documentref;
    this.documentRef.onSnapshot(
      (snapshot: DocumentSnapshot<DocumentData>) => {
        this.assocbuygold965 = snapshot.data().assocbuygold965;
        this.assocsellgold965 = snapshot.data().assocsellgold965;
      }
    );

  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
