

<div class="container">
  <div class="kpx_login">
        <h3 class="kpx_authTitle">Login</h3>
        <div class="row kpx_row-sm-offset-3 kpx_socialButtons">
          <div class="col-xs-2 col-sm-2">
            <a  class="btn btn-lg btn-block kpx_btn-facebook" data-toggle="tooltip" data-placement="top" title="Facebook" (click) = "onLoginFacebook()">
              <i class="fa fa-facebook fa-2x"></i>
              <span class="hidden-xs"></span>
            </a>
          </div>
          <div class="col-xs-2 col-sm-2">
            <a class="btn btn-lg btn-block kpx_btn-yahoo" data-toggle="tooltip" data-placement="top" title="Yahoo" (click) = "onLoginYahoo()">
              <i class="fa fa-yahoo fa-2x"></i>
              <span class="hidden-xs"></span>
            </a>
          </div>
          <div class="col-xs-2 col-sm-2">
            <a class="btn btn-lg btn-block kpx_btn-google" data-toggle="tooltip" data-placement="top" title="Google" (click) = "onLoginGoogle()">
              <i class="fa fa-google fa-2x"></i>
              <span class="hidden-xs"></span>
            </a>
          </div>
    </div><br>

<div class="row mt-4 mb-4 justify-content-md-center">

  <div class="col-md">
  <div class="card" >
     <h5 class="card-title"><a>Premium Account</a></h5>
    <img class="img-fluid rounded "  >
    <div class="card-body">
      <p class="card-text">ขั้นตอนการขอเข้า หน้า Updated Stocks</p>
      <p class="card-text">1) กด Register via Facebook เพื่อสร้างคำขอ Account ผ่านระบบ</p>
      <p class="card-text">2) ติดต่อและแจ้ง E-mail ที่ผูกกับ Facebook ให้กับ บจก ห้างทองจิบฮุย </p>
      <p class="card-text">3) เมื่อ บจก ห้างทองจิบฮุย อนุมัติสำเร็จท่านจะสามารถ Login โดยใช้ Facebook Account ส่วนตัวของท่านได้ </p>

    </div>

  </div>
  </div>
</div>
</div>
<footer>

    <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
      <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
    </div>
</footer>
