import { Component, OnInit  } from '@angular/core';
import { DocumentData } from 'angularfire2/firestore';
import { ProductGalleryService } from './services/product-gallery.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { UsermngmtService } from './services/usermngmt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isMainMenuCollapsed = false;
  title = 'jh-web-app';
  lat = 43.653908;
  lng = -79.384293;
  isCustomerLogOn: boolean;
  isPremiumAccount: boolean;
  userAcctStr: String;
  constructor(public userMngmtServ: UsermngmtService, public afAuth: AngularFireAuth) { }


  ngOnInit(): void {
    this.afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.isCustomerLogOn = true;
        user.getIdTokenResult().then(
          (getIdTokenResult) => {
            if (getIdTokenResult.claims.isCustomer) {
              this.userAcctStr = 'Account: ' + user.email + '/' + 'Premium';
              this.isPremiumAccount = true;
            } else {
              this.userAcctStr = 'Account: ' + user.email + '/' + 'Standard';
              this.isPremiumAccount = false;
            }
          }
        );
      } else {
        this.userAcctStr = null;
        this.isCustomerLogOn = false;
        this.isPremiumAccount = null;
      }
    });

  }




  logout() {
    this.afAuth.auth.signOut().then(
      (doc) => {
        console.log(doc);
        return doc;
      }
    );
  }
}
