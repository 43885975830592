<div class="container-fluid  mt-3">
  <div class="container" style="background-color:white">
  <div class="row justify-content-center">
      <p class="fw-normal">With more than 2,000 designs at our disposal, we have been offering high quality 96.5%
        gold Jewelery to gold shops around Thailand for more than 50 years.</p>
  </div>
  <div class="row justify-content-center">
    <p class="padding-xl fw-normal thai">ที่ร้านทองจิบฮุย ทองรูปพรรณมีมากกว่า 2,000 ดีไซน์ ไม่ว่าจะเป็นลวดลายของสร้อยคอ สร้อยข้อมือ แหวน กำไล ต่างหู และจี้ทอง
      ร้านทองจิบฮุยจำหน่ายเครื่องประดับทอง 96.5% ได้มาตรฐานสำนักงานคณะกรรมการคุ้มครองผู้บริโภค (สคบ.) ผ่านกระบวนการผลิตที่มีคุณภาพให้กับร้านทองค้าปลีกทั่วประเทศไทยมานานกว่า 40ปี
    </p>
  </div>
    <div class="row justify-content-center">
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0006'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-11.png?alt=media&token=03fb9db6-8d59-4681-8cd7-54388506f070" />

            <div class="carousel-caption" >
              <h6>Earrings</h6>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0003'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-13.png?alt=media&token=41789ec4-6bfb-452e-8ca8-5b4db516213a" />
            <div class="carousel-caption" >
              <h6>Bracelets</h6>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0001'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-21.png?alt=media&token=3ffdaf4d-6f1c-4950-b504-16db991a4592" />
            <div class="carousel-caption" >
              <h6>Necklaces</h6>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row  justify-content-center">
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0002'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-6.png?alt=media&token=ce613e0d-7c6a-4378-8fbc-1bc2fc469e80" />
            <div class="carousel-caption" >
              <h6>Wristbands</h6>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0005'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-7.png?alt=media&token=b671eda6-2baa-4aaa-a566-4d75ad9d4afa" />
            <div class="carousel-caption" >
              <h6>Pendants</h6>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-3 d-flex mt-3">
        <div class="mx-auto">
          <a routerLink="/gold965jewelery" [queryParams]="{category: '0004'}">
            <img class="img-fluid" alt="Responsive image" src="https://firebasestorage.googleapis.com/v0/b/jib-hui.appspot.com/o/web_img%2Fjibhui-bg-icon-9.png?alt=media&token=c418bb0f-d0cd-45ce-9b5b-85b8220bb408" />
            <div class="carousel-caption" >
              <h6>Rings</h6>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <footer>

      <div class = "d-flex justify-content-center w-100 p-1 mt-3" style="background-color: #800020;">
        <p style="font-size:15px; color:white" class="fw-normal">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
      </div>
  </footer>
