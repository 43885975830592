
<div class="title div-page-title">
  <h1 class="thai">โปรดเลือกภาษา</h1><h1>Please choose language</h1>
</div>
<div class="container-fluid mb-5 " style="background-color: #F5F5F5">
  <div class="row justify-content-center mt-4">
      <div class="col-md-6 mb-3 text-center">
        <a  href="#" routerLink="/registration-th" >
        <button type="button" class="btn btn-primary btn-lg  btn-block thai" style="background-color: #800020; border-color: #9A9A4E;">ภาษาไทย</button>
        </a>
      </div>
      <div class="col-md-6 mb-3 text-center">
        <a  href="#" routerLink="/registration" >
        <button type="button" class="btn btn-primary btn-lg  btn-block" style="background-color: #800020; border-color: #9A9A4E; ">English</button>
        </a>
      </div>
  </div>

</div>
