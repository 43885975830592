<p>admin works!</p>
<div class="form-group">

    <label for="file">Choose File</label>
    <input type="file"
            multiple
           id="file"
           (change)="onFileSelected($event)">
</div>
<footer>

    <div class = "d-flex justify-content-center w-100 p-1" style="background-color: #800020;">
      <p style="font-size:15px; color:white">All images and materials are copyright protected and are the property of Jib Hui Goldsmith Co.,Ltd.<p>
    </div>
</footer>
